import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { motion } from "framer-motion";
import { checkForFeature } from "services/featureFlagHOC";
import { IoStar } from "react-icons/io5";

const useStyles = makeStyles((theme) => ({
    listItem: {
        background: "#FFFFFF",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        minHeight: "5rem",
        margin: "0",
        marginBottom: "10px",
        padding: "1rem",
        border: "1px solid #ECECEC",
        borderRadius: "0.5rem",
        color: "#2C2C2C",
        // cursor: "pointer",
        "&:hover": {
            color: `${theme.palette.primary.main}`,
            borderRadius: 8,
            border: `1px solid ${theme.palette.primary.main}`,
        },
    },
    icon: {
        color: `${theme.palette.primary.main}`,
    },
    item: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    paddingTop: {
        paddingTop: "0",
    },
}));

/**
 * This component is used to display the side navigation items
 *
 * @component
 * @param {String} name - The name of the item
 * @param {String} subtitle - The subtitle of the item
 * @param {String} redirectLink - The link to redirect to
 * @param {Array} [userFeatures] - The features of the user its optional and can be null
 * @param {Array} [requiredFeatures] - The required features to access the item its optional and can be null
 * @returns {Component}
 *
 */
function SideNavItem({ name, subtitle, redirectLink, userFeatures, requiredFeatures }) {
    const classes = useStyles();
    const isFeatureEnabled = checkForFeature(userFeatures, requiredFeatures);

    return (
        <Link to={redirectLink} color="primary" underline="none">
            {" "}
            <motion.div
                className={clsx(classes.listItem, {
                    [classes.paddingTop]: !isFeatureEnabled,
                })}
                whileHover={{ scale: 1.03 }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.1rem",
                    }}
                >
                    {!isFeatureEnabled && (
                        <span
                            style={{
                                position: "relative",
                                top: "20px",
                                alignSelf: "flex-end",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                                backgroundColor: "#FCD6BB",
                                color: "#FF6A39",
                                padding: "4px 1px 4px 4px",
                                width: "17%",
                                borderRadius: "5px",
                            }}
                        >
                            <IoStar
                                style={{
                                    color: "#FF6A39",
                                }}
                            />
                            <Typography
                                sx={{
                                    width: "100%",
                                    fontSize: "0.8rem",
                                    fontWeight: 500,
                                }}
                            >
                                Pro
                            </Typography>
                        </span>
                    )}
                    <span className={classes.item}>
                        <Typography
                            sx={{
                                width: "100%",
                                textAlign: "left",
                                fontSize: "0.94rem",
                                fontWeight: 600,
                            }}
                        >
                            {name}
                        </Typography>
                    </span>
                    <Typography
                        sx={{
                            color: "#ACACAC",
                            width: "100%",
                            textAlign: "left",
                            fontSize: "0.7rem",
                            fontWeight: 500,
                            lineHeight: "14px",
                        }}
                    >
                        {subtitle}
                    </Typography>
                </div>
            </motion.div>
        </Link>
    );
}

export default SideNavItem;

import { combineReducers } from "redux";

import { dataSourcesReducer } from "./dataSourcesReducer";
import { dataWareHousesReducer } from "./dataWareHousesReducer";
import notifierReducer from "./notifierReducer";
import { dataSourceReducer } from "./dataSourceReducer";
import { authReducer } from "./authReducer";
import { transformationsReducer } from "./transformationsReducer";
import { usersReducer } from "./usersReducer";
import { dashboardReducer } from "./dashboardReducer";
import { configuredReportReducer } from "./configuredReportReducer";
import { realtimeNotification } from "./realtimeNotification";
import { reverseConnectorsReducer } from "./reverseConnectorsReducer";
import { reverseConnectorReducer } from "./reverseConnectorReducer";
import { dataModelReducer } from "./dataModelReducer";
import { configuredSyncReducer } from "./configuredSyncReducer";
import { segmentsReducer } from "./segmentsReducer";
import { uiStateReducer } from "./uiStateReducer";
import { homepageReducer } from "./homepageReducer";
import { bannerReducer } from "./bannerReducer";
import { homepageReverseReducer } from "./homepageReverseReducer";
import { dbtTransformationsReducer } from "./dbtTransformations";
import { announcementReducer } from "./announcementReducer";
import { orchestrationReducer } from "./orchestrationReducer";
import { onBoardingReducer } from "./onboardingReducer";
import { dbtCloudTransformationsReducer } from "./dbtCloudTransformationReducer";
import { assetReducer } from "./assetsReducer";
import { workspaceReducer } from "./workspaceReducer";

const rootReducer = combineReducers({
    dataSources: dataSourcesReducer,
    dataSource: dataSourceReducer,
    dataWareHouses: dataWareHousesReducer,
    notification: notifierReducer,
    auth: authReducer,
    transformations: transformationsReducer,
    users: usersReducer,
    dashboard: dashboardReducer,
    configuredReport: configuredReportReducer,
    realtimeNotification: realtimeNotification,
    reverseConnectors: reverseConnectorsReducer,
    reverseConnector: reverseConnectorReducer,
    dataModel: dataModelReducer,
    configuredSync: configuredSyncReducer,
    segments: segmentsReducer,
    uiStates: uiStateReducer,
    homepage: homepageReducer,
    banner: bannerReducer,
    homepageReverse: homepageReverseReducer,
    announcement: announcementReducer,
    orchestration: orchestrationReducer,
    dbtTransformations: dbtTransformationsReducer,
    announcement: announcementReducer,
    assets:assetReducer,    
    dbtCloudTransformations: dbtCloudTransformationsReducer,
    onboarding: onBoardingReducer,
    workspaces: workspaceReducer,
    orchestration: orchestrationReducer,
});

export default rootReducer;

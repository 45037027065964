import React from "react";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import { fetchConfiguredReports } from "redux/actions/dataSourceActions";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar as enqueueSnackbarAction, closeSnackbar as closeSnackbarAction } from "redux/actions/NotifierActions";
import Tooltip from "@mui/material/Tooltip";
import CheckPermissions from "services/CheckPermissions";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import ReorderOutlinedIcon from "@mui/icons-material/ReorderOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { TOGGLE_RESTRICT_POPUP } from "redux/actionTypes/actionTypes";
import {checkIfExceededLimitForActiveReports} from 'services/FreePlanLimitsFunctions'

const closeSnackbar = (dispatch, ...args) => dispatch(closeSnackbarAction(...args));

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function ConfiguredReportActionButtons({ datasourceSlug, reportId, fontSize,report_steps_id }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const dataSources = useSelector((state) => state.dataSources);
    const user = useSelector((state) => state.users.user)
    // const [loading, setLoading] = React.useState(false);
    const [reportLoading, setReportLoading] = React.useState(null);

    //using api hook
    const [, manualRunIsLoading, runReport] = useApi();
    const [, reportDeleting, deleteReport] = useApi();

    const handleManualRun = async (configured_report_id) => {
        if (checkIfExceededLimitForActiveReports(user,dataSources,datasourceSlug)){
            dispatch({ type: TOGGLE_RESTRICT_POPUP, payload: { show_popup: true, isClosable: true, showUpgradeButtons: true } })
            return
        }
        setReportLoading(configured_report_id);
        await runReport({
            module: "configuredReport",
            method: "runReport",
            successToast: "Configured data pipeline run was successful.",
            apiData: {
                datasourceSlug: datasourceSlug,
                configuredReportId: reportId,
            },
        });
        setReportLoading(null);
    };

    const handleDelete = async (configured_report_id) => {
        if (window.confirm("Are you sure you wanna delete this report?")) {
            setReportLoading(configured_report_id);
            await deleteReport({
                module: "configuredReport",
                method: "deleteReport",
                successToast: "Configured data pipeline deleted successfully.",
                apiData: {
                    datasourceSlug: datasourceSlug,
                    configuredReportId: reportId,
                },
            });
            dispatch(fetchConfiguredReports(datasourceSlug));
            setReportLoading(null);
        } else {
            return;
        }
    };

    return (
        <Grid container>
            <CheckPermissions requiredPermission={["report.edit"]}>
                <Tooltip title="Edit Data Pipeline">
                    <Link to={`/datasource/${datasourceSlug}/detail/editreport/${reportId}`}>
                        <EditOutlinedIcon
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            {datasourceSlug !== "webhooks" && !report_steps_id ? (
                <CheckPermissions requiredPermission={["report.run"]}>
                    <Tooltip title="Run Data Pipeline">
                        {manualRunIsLoading && reportLoading === reportId ? (
                            <CircularProgress size={15} />
                        ) : (
                            <PlayCircleOutlineIcon
                                className={clsx(classes.icon, {
                                    [classes.font15]: fontSize !== undefined,
                                })}
                                fontSize="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleManualRun(reportId);
                                }}
                            />
                        )}
                    </Tooltip>
                </CheckPermissions>
            ) : null}
            {datasourceSlug !== "files" && datasourceSlug !== "webhooks" ? (
                <CheckPermissions requiredPermission={["report.edit"]}>
                    <Tooltip title="Copy Data Pipeline">
                        <Link to={`/datasource/${datasourceSlug}/report/new?configured_report_id=${reportId}`}>
                            <FileCopyOutlinedIcon
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                className={clsx(classes.icon, {
                                    [classes.font15]: fontSize !== undefined,
                                })}
                                fontSize="small"
                            />
                        </Link>
                    </Tooltip>
                </CheckPermissions>
            ) : null}
            <CheckPermissions requiredPermission={["report.edit"]}>
                <Tooltip title="Last Run">
                    <Link to={`/datasource/${datasourceSlug}/detail/editreport/${reportId}?tab=last_run`}>
                        <QueryBuilderOutlinedIcon
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["report.edit"]}>
                <Tooltip title="Change History">
                    <Link to={`/datasource/${datasourceSlug}/detail/editreport/${reportId}?tab=change_history`}>
                        <ReorderOutlinedIcon
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["report.edit"]}>
                <Tooltip title="Delete Data Pipeline">
                    {reportDeleting && reportLoading === reportId ? (
                        <CircularProgress size={15} />
                    ) : (
                        <DeleteOutlineOutlinedIcon
                            className={clsx(classes.icon, {
                                [classes.font15]: fontSize !== undefined,
                            })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(reportId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckPermissions>
        </Grid>
    );
}

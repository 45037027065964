import { Autocomplete, Button, Grid, TextField, Typography, Paper, ButtonGroup, Tooltip, IconButton } from "@mui/material";
import React from "react";
import { nodeIconMapper } from "./common/iconMapper";
import { Link } from "react-router-dom";
import Drawer from "./Nodes/common/NodeConfigrationDrawer";
import { SchedulingComponent } from "components/common/Scheduling/Scheduling";
import { useReactFlow, useNodes, useEdges } from "reactflow";
import { detectLoopsInGraph, detectIslandsInGraph } from "./common/functions";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import { CloseOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
    item: {
        "&:hover": {
            backgroundColor: "#ededed",
            borderRadius: "8px",
            rotate: "10deg",
            transition: "ease",
            transform: "scale(1.05)",
        },
    },
    openItem: {
        "&:hover": {
            backgroundColor: "#ededed",
            borderRadius: "8px",
            rotate: "3deg",
            transition: "ease",
        },
    },
}));

/**
 * React component representing the toolbar for the orchestration flow.
 *
 * @param {Object} props - Props object containing orchestration data and related functions.
 * @returns {JSX.Element} A React component representing the orchestration toolbar.
 */
export default function FlowToolbar(props) {
    const { setEdges, setNodes } = useReactFlow();

    const classes = useStyles();
    const nodes = useNodes();
    const edges = useEdges();

    console.log(edges);

    const [open, setOpen] = React.useState(false);
    const [name, setName] = React.useState(props.orchestrationName ? props.orchestrationName : "");
    const [errorMessage, setErrorMessage] = React.useState(null);

    /**
     * Handles the save action for the scheduling component.
     *
     * @param {Object} schedule - The scheduling data to be saved.
     */
    const handleScheduleSave = (schedule) => {
        props.onSave(schedule, name);
    };

    const validateNodeConfigration = () => {
        let check = false;
        // validate if nodepicker is there on canvas
        if (nodes.filter((node) => node.type === "nodePicker").length > 0) {
            setErrorMessage(`Please select a node from the Node Picker.`);
            return true;
        }
        // validate if there are no nodes on the canvas except start node
        if (nodes.length === 1 && nodes[0].type === "startNode") {
            setErrorMessage(`Please connect and configure atleast one node from start node.`);
            return true;
        }
        // validate that decision node is only connected to datasource node
        nodes.forEach((node) => {
            if (node.type === "decisionNode") {
                edges.forEach((edge) => {
                    if (edge.target === node.id) {
                        let sourceNode = nodes.filter((node) => node.id === edge.source)[0];
                        if (sourceNode.type !== "datasourceNode") {
                            check = true;
                            setErrorMessage(`Decision Node can only be connected to Datasource Node.`);
                            node.data.borderColor = "red";
                            node.data.status = "ERROR";
                            node.data.errorMessage = "Can only be configured for Datasource Node";
                            edge.style = { stroke: "red" };
                        }
                    }
                });
            }
        });
        //  validate if each node is configured properly
        nodes.forEach((node) => {
            if (node.type === "datasourceNode") {
                if (node.data?.configuration?.configuredReport?.id === undefined) {
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
                if (
                    node.data?.configuration?.nodeExecutionConfiguration?.run_pipeline?.value === false &&
                    node.data?.configuration?.nodeExecutionConfiguration?.freshnessDuration?.value === undefined
                ) {
                    setErrorMessage(`Freshness Duration is a required key in Datasource Node.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
            if (node.type === "reverseConnectorNode") {
                if (node.data?.configuration?.configuredSync?.id === undefined) {
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
            if (node.type === "sqlTransformationNode") {
                if (node.data?.configuration?.transformation?.id === undefined) {
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
            if (node.type === "decisionNode") {
                if (node.data?.configuration?.nodeExecutionConfiguration?.rowsMoved === undefined) {
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
            if (node.type === "dbtTransformationNode") {
                if (node.data?.configuration?.dbtTransformation?.id === undefined) {
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
            if (node.type === "powerBIRefreshNode") {
                console.log(node.data?.configuration?.nodeExecutionConfiguration?.credential?.value);
                if (
                    node.data?.configuration?.nodeExecutionConfiguration?.credential?.value === undefined ||
                    node.data?.configuration?.nodeExecutionConfiguration?.dataset?.value === undefined
                ) {
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
            if (node.type === "tableauRefreshNode") {
                if (/** condition to check node if valid for both credential configured nodes 
                and modal configured nodes */

                    node.data?.configuration?.nodeExecutionConfiguration?.credential === undefined && (
                    node.data?.configuration?.nodeExecutionConfiguration?.site_name === undefined ||
                    node.data?.configuration?.nodeExecutionConfiguration?.personal_access_token_name === undefined ||
                    node.data?.configuration?.nodeExecutionConfiguration?.personal_access_token_secret === undefined ||
                    node.data?.configuration?.nodeExecutionConfiguration?.server_url === undefined)
                ) {
                    console.log(node.data?.configuration?.nodeExecutionConfiguration)
                    setErrorMessage(`Some nodes in the graph are not configured.`);
                    check = true;
                    node.data.borderColor = "red";
                    node.data.status = "ERROR";
                    node.data.errorMessage = "Unconfigured Node.";
                }
            }
        });
        if (check) {
            setNodes(nodes);
            setEdges(edges);
        }
        return check;
    };

    /**
     * Event handler for opening the configuration drawer.
     * Checks for loops and disconnected nodes before opening the drawer.
     */
    const handleDrawerOpen = () => {
        const check = validateNodeConfigration();
        // return in case nodes are not configured
        if (check) return;
        const { loopNodes, loopEdges } = detectLoopsInGraph(nodes, edges);
        if (loopEdges.length === 0) {
            let { islandNodes, islandEdges } = detectIslandsInGraph(nodes, edges);
            console.log(islandNodes);
            if (islandEdges.length > 0 || islandNodes.length > 0) {
                setErrorMessage("There are some nodes which are not connected to the flow.");
                setEdges((edges) =>
                    edges.map((edge) => {
                        if (islandEdges.includes(edge.id)) {
                            return { ...edge, style: { stroke: "red" } };
                        } else {
                            return edge;
                        }
                    })
                );
                setNodes((nodes) =>
                    nodes.map((node) => {
                        if (islandNodes.includes(node.id)) {
                            return {
                                ...node,
                                data: {
                                    ...node.data,
                                    borderColor: "red",
                                    status: "ERROR",
                                    errorMessage: "Disconnected Flow.",
                                },
                            };
                        } else {
                            return {
                                ...node,
                                data: {
                                    ...node.data,
                                    status: undefined,
                                    borderColor: "#E7E7E7",
                                },
                            };
                        }
                    })
                );
            } else {
                setErrorMessage(null);
                setEdges((edges) =>
                    edges.map((edge) => {
                        delete edge.style;
                        return edge;
                    })
                );
                setEdges((edges) => {
                    return edges.filter((edge) => {
                        if (
                            nodes.filter((node) => node.id === edge.source).length > 0 &&
                            nodes.filter((node) => node.id === edge.target).length > 0
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                });
                setNodes((nodes) =>
                    nodes.map((node) => {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                isNodeToolbarOpen: false,
                                borderColor: "#E7E7E7",
                                status: undefined,
                            },
                        };
                    })
                );
                setOpen((open) => !open);
            }
        } else {
            setErrorMessage("There are loops in your flow.");
            setEdges((edges) =>
                edges.map((edge) => {
                    if (loopEdges.includes(edge.id)) {
                        return { ...edge, style: { stroke: "red" } };
                    } else {
                        delete edge["style"];
                        return edge;
                    }
                })
            );
            setNodes((nodes) =>
                nodes.map((node) => {
                    if (loopNodes.includes(node.id)) {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                borderColor: "red",
                                status: "ERROR",
                                errorMessage: "In a loop.",
                            },
                        };
                    } else {
                        return {
                            ...node,
                            data: {
                                ...node.data,
                                status: undefined,
                                borderColor: "#E7E7E7",
                            },
                        };
                    }
                })
            );
        }
    };

    /**
     * Handles edge type change. Changes type of edge to default type
     * @param {*} value
     */
    const handleEdgeTypeChange = (value) => {
        if (value === "Smooth Step") {
            setEdges(() => edges.map((edge) => ({ ...edge, type: "smoothstep" })));
        } else {
            setEdges(() => edges.map((edge) => ({ ...edge, type: "bezier" })));
        }
    };

    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    };

    return (
        <Paper elevation={1} style={{ marginBottom: 10, backgroundColor: "#f9f9f9" }}>
            <Grid justifyContent="flex-end" container style={{ padding: "10px", borderRadius: 5 }}>
                <Grid item container justifyContent="space-between" alignItems="center">
                    <Grid item container alignItems="center" justifyContent="space-between" spacing={1}>
                        <Grid
                            item
                            style={{
                                border: "1px solid #cbcbcb",
                                borderRadius: 4,
                                paddingLeft: 0,
                                paddingTop: 0,
                            }}
                        >
                            <div style={{ backgroundColor: "#d5d5d5", padding: "5px 5px" }}>
                                <Typography className="semiBoldText" style={{ fontSize: 13 }}>
                                    Pick & Drag Node
                                </Typography>
                            </div>
                            <Grid container>
                                {props.nodesData.map((category) => (
                                    <>
                                        {category.nodes.map((node) => (
                                            <Tooltip title={node.name}>
                                                <Grid
                                                    draggable
                                                    className={clsx("dndnode", classes.item)}
                                                    onDragStart={(event) => onDragStart(event, node.slug)}
                                                    item
                                                    style={{ marginRight: 20, marginLeft: 20, marginTop: 3, marginBottom: 3 }}
                                                >
                                                    <img
                                                        style={{ height: 35, width: 35 }}
                                                        src={nodeIconMapper[node.slug]}
                                                        alt="node icon"
                                                    />
                                                </Grid>
                                            </Tooltip>
                                        ))}
                                    </>
                                ))}
                            </Grid>
                        </Grid>
                        {errorMessage && (
                            <Grid style={{ border: "1px solid red", backgroundColor: "rgb(255 233 233)", borderRadius: 8 }}>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Typography className="semiBoldText" style={{ color: "red", padding: "5px 10px" }}>
                                        {errorMessage}
                                    </Typography>
                                    <IconButton onClick={() => setErrorMessage(null)}>
                                        <CloseOutlined style={{ color: "red" }} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            {/* <div style={{ width: 300 }}>
                                <Autocomplete
                                    options={["Smooth Step", "Bezier"]}
                                    onChange={(e, value) => handleEdgeTypeChange(value)}
                                    defaultValue="Smooth Step"
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField {...params} label="Step Type" margin="normal" variant="outlined" fullWidth />
                                    )}
                                />
                            </div> */}
                            {/* <ButtonGroup>
                                <Tooltip title="Change edge type to Bezier">
                                    <Button
                                        color="secondary"
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        onClick={() => handleEdgeTypeChange("Bezier")}
                                        variant={
                                            edges[0]?.type === "bezier" || edges[0]?.type === undefined ? "contained" : "outlined"
                                        }
                                    >
                                        Bezier
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Change edge type to Smooth Step">
                                    <Button
                                        color="secondary"
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        onClick={() => handleEdgeTypeChange("Smooth Step")}
                                        variant={edges[0]?.type === "smoothstep" ? "contained" : "outlined"}
                                    >
                                        Smooth Step
                                    </Button>
                                </Tooltip>
                            </ButtonGroup> */}
                            <Button
                                color="primary"
                                onClick={handleDrawerOpen}
                                variant="contained"
                                className="margin0"
                                style={{ marginLeft: 10 }}
                            >
                                Schedule & Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Drawer drawerTitle="Schedule & Save" isDrawerOpen={open} setIsDrawerOpen={setOpen}>
                    <Grid style={{ border: "1px solid #EAEAEA", borderRadius: 15, padding: 20, marginTop: 15 }}>
                        <Typography>Name</Typography>
                        <TextField
                            style={{ marginTop: 20, marginBottom: 10 }}
                            fullWidth
                            variant="outlined"
                            error={props.nameError !== null}
                            helperText={props.nameError}
                            value={name}
                            onChange={(e) => {
                                props.setNameError(null);
                                setName(e.target.value);
                            }}
                            size="small"
                            label="Orchestration Name"
                        />
                    </Grid>
                    <Grid style={{ border: "1px solid #EAEAEA", borderRadius: 15, padding: 20, marginTop: 15 }}>
                        <Typography>Scheduling</Typography>
                        <SchedulingComponent
                            mergeStepData={props.mergeStepData}
                            scheduleData={
                                props.orchestrationData?.orchestration_schedule === null
                                    ? {}
                                    : props.orchestrationData?.orchestration_schedule
                            }
                            isSubmitting={props.isSaving}
                            hideBack={true}
                            handleNext={props.handleNext}
                            handleBack={props.handleBack}
                            isLastStep={true}
                            handleSubmit={handleScheduleSave}
                            editMode={props.mode === "EDIT"}
                        />
                    </Grid>
                </Drawer>
            </Grid>
        </Paper>
    );
}

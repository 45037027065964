import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import routes from "../config/routes";
import { fetchUser, fetchInvoiceData, fetchLatestAnnouncements } from "../redux/actions/usersAction";
import Auth from "./Auth";
import useEventTracker from "./EventTracker/useEventTracker";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { Grid, Typography, Button } from "@mui/material";
import CheckPermissions from "./CheckPermissions";
import { useDispatch } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import FeatureFlagHOC from "./featureFlagHOC";
import AccountHOC from "./AccountHOC";
import { onIdTokenChanged } from "firebase/auth";
import { auth } from "config/firebase";
import Cookies from "js-cookie";
import axios from "config/axios";
import { Helmet } from "react-helmet";
import { errorBoundary } from "./ErrorBoundaryHOC";
const Onboarding = React.lazy(() => import("components/Onboarding/index"));
/**
 * Component to render Website's Head Manager
 * @param {*} props
 */
const HeadManager = ({ routeData }) => {
    return (
        <Helmet>
            <title>{routeData?.title ? routeData?.title : "DataChannel"}</title>
            {routeData.showTicketComponent && (
                <script type="text/javascript">
                    {(window.ZohoHCAsap =
                        window.ZohoHCAsap ||
                        function (a, b) {
                            ZohoHCAsap[a] = b;
                        }) &&
                        (function () {
                            var d = document;
                            var s = d.createElement("script");
                            s.type = "text/javascript";
                            s.defer = true;
                            s.src = "https://desk.zoho.com/portal/api/web/inapp/423943000000446689?orgId=695305046";
                            d.getElementsByTagName("head")[0].appendChild(s);
                        })()}
                </script>
            )}
        </Helmet>
    );
};

/**
 * Component to render the authenticated components
 * @param {*} props
 * @returns
 */
const AuthenticatedComponent = (props) => {
    const dispatch = useDispatch();
    const EventTracker = useEventTracker();
    let users = useSelector((state) => state.users);
    let meUserIsLoading = useSelector((state) => state.users.meUserIsLoading);
    const isOnboarding = useSelector((state) => state.uiStates.isOnboarding);

    useEffect(async () => {
        onIdTokenChanged(auth, async (user) => {
            const idToken = await user.getIdToken(false);
            Cookies.remove("accessToken");
            Cookies.remove("idToken");
            Cookies.set("idToken", idToken);
            Cookies.set("accessToken", idToken);
            axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
            console.log(users?.user);
            if (user && users?.user === null) {
                dispatch(fetchUser());
                dispatch(fetchInvoiceData());
                dispatch(fetchLatestAnnouncements());
            }
        });
    }, []);

    /**
     * useEffect to send log in event and set a localstorage key so that log in event doesn't
     * get emitted in case of a refresh
     */
    useEffect(() => {
        if (window.localStorage.getItem("hasSentLoginEvent") === null && users?.user !== null) {
            window.localStorage.setItem("hasSentLoginEvent", true);
            EventTracker.setUserId();
            EventTracker.trackSelfDescribingEvent("user_login");
            EventTracker.trackGaEvent("login");
        }
    }, users?.user);

    if (meUserIsLoading || users?.user === null || users?.user?.length === 0) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh", width: "100vw" }}>
                <CircularProgress />
            </Grid>
        );
    }
    return props.route.checkAccount ? (
        <AccountHOC>
            <FeatureFlagHOC redirect={true} requiredFeature={props.requiredFeature} disablePage={true} showPayPopup={true}>
                {isOnboarding && !props.route.path?.startsWith("/settings")  ? (
                    <CheckPermissions requiredPermission={props.requiredPermission} showNotFound={true}>
                        <props.route.layout pageTitle={"Home"} comp={Onboarding} features={props.route.features} />
                    </CheckPermissions>
                ) : (
                    <CheckPermissions requiredPermission={props.requiredPermission} showNotFound={true}>
                        {props.route.layout === null ? (
                            <props.route.comp {...props} />
                        ) : (
                            <props.route.layout
                                pageTitle={props.route.title}
                                comp={props.route.comp}
                                features={props.route.features}
                            />
                        )}
                    </CheckPermissions>
                )}
            </FeatureFlagHOC>
        </AccountHOC>
    ) : (
        <FeatureFlagHOC requiredFeature={props.requiredFeature} showPayPopup={true}>
            <CheckPermissions requiredPermission={props.requiredPermission} showNotFound={true}>
                {props.route.layout === null ? (
                    <props.route.comp {...props} />
                ) : (
                    <props.route.layout pageTitle={props.route.title} comp={props.route.comp} features={props.route.features} />
                )}
            </CheckPermissions>
        </FeatureFlagHOC>
    );
};

const MainComponent = (props) => {
    return (
        <>
            <HeadManager routeData={props.route} />
            {props.route.protected ? (
                <Auth>
                    <AuthenticatedComponent {...props} />
                </Auth>
            ) : props.route.layout === null ? (
                <props.route.comp {...props} />
            ) : (
                <props.route.layout pageTitle={props.route.title} comp={props.route.comp} features={props.route.features} />
            )}
        </>
    );
};

export const NotFound = () => (
    <Grid container justifyContent="center" alignItems="center" style={{ height: "100vh" }}>
        <Grid item>
            <Typography variant="h5">404 - Not Found!</Typography>
            <Link to="/">
                <Button variant="contained" color="primary">
                    Go Home
                </Button>
            </Link>
        </Grid>
    </Grid>
);

const checkPermissions = (routePermissions, userPermissions) => {
    let check = false;
    if (!Array.isArray(routePermissions)) {
        return true;
    }
    if (routePermissions.length === 0) {
        return true;
    }
    routePermissions.forEach((routePermission) => {
        userPermissions.forEach((userPermission) => {
            if (routePermission === userPermission) {
                check = true;
            }
        });
    });
    return check;
};

const Routing = ({ history }) => {
    const location = useLocation();
    return (
        <>
            <AnimatePresence>
                <Routes location={location} key={location.key}>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={
                                <MainComponent
                                    route={route}
                                    requiredPermission={route.permissions}
                                    requiredFeature={route.features}
                                />
                            }
                        />
                    ))}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AnimatePresence>
        </>
    );
};

export default errorBoundary(Routing);

import { Typography, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import NodeToolbar from "../common/NodeToolbar";
import NodeConfigrationDrawer from "../common/NodeConfigrationDrawer";
import NodeStatusHOC from "../common/NodeStatusHOC";
import Configration from "./Configration";
import { nodeIconMapper } from "components/Orchestration/AddOrchestration/common/iconMapper";
import flowConfig from "../../common/FlowConfig";
import ContextMenu from "components/common/UIComponents/ContextMenu";
import { useReactFlow, useNodes, useEdges } from "reactflow";
import { useSelector } from "react-redux";
import HandleWrapper from "../common/HandleWrapper";

export default memo(({ data, isConnectable, id }) => {
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const { setNodes, setEdges } = useReactFlow();
    const orchestration = useSelector((state) => state.orchestration);
    const nodes = useNodes();
    const edges = useEdges();

    React.useEffect(() => {
        if (orchestration.addingNodesToCanvas === false) setIsDrawerOpen(flowConfig?.defaultDrawerState);
    }, []);

    /**
     * Handles the deletion of the node and its associated edges from the flow.
     */
    const handleDelete = () => {
        setNodes(() => nodes.filter((node) => node.id !== id));
        setEdges(() => edges.filter((edge) => edge.source !== id && edge.target !== id));
    };

    /**
     * Handles the swapping of the current node with a different node type.
     */
    const handleSwap = () => {
        setNodes((nodes) => {
            nodes.forEach((node) => {
                if (node.id === id) {
                    node.type = "nodePicker";
                }
            });
            return nodes;
        });
    };
    /**
     * function to handle double click and open edit menu
     */
    const handleClick = (e) => {
        /**
         * handle double click
         */
        switch (e.detail) {
            case 2:
                setIsDrawerOpen(true);
                break;
        }
    };

    return (
        <NodeStatusHOC status={data.status} nodeData={data} nodeId={id}>
            <ContextMenu
                componentName="orchestration_nodes"
                data={{
                    onEdit: () => setIsDrawerOpen(true),
                    onDelete: handleDelete,
                    onSwap: handleSwap,
                }}
            >
                <div
                    style={{
                        background: "white",
                        borderWidth: `2px 2px 2px 6px`,
                        borderRadius: 5,
                        borderColor: `${data.borderColor} ${data.borderColor} ${data.borderColor} #F5DB61`,
                        borderStyle: "solid",
                        borderImage: "initial",
                    }}
                    onClick={handleClick}
                >
                    <Grid container style={{ width: 350 }}>
                        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                            <Typography
                                style={{ padding: "5px 0px", marginLeft: 10, marginTop: 5, marginBottom: 5 }}
                                className="size-14 blackText"
                            >
                                <span>
                                    <img src={nodeIconMapper["powerBIRefreshNode"]} style={{ height: "35px" }} />
                                </span>{" "}
                                PowerBI Node
                            </Typography>
                            <NodeToolbar data={data} nodeId={id} onEdit={() => setIsDrawerOpen(true)} />
                        </Grid>
                        <div
                            style={{
                                marginLeft: 15,
                                marginRight: 15,
                                width: "100%",
                                marginBottom: 15,
                                border: "1px solid #F5DB61",
                                background: "#fff6ca80",
                                borderRadius: 4,
                            }}
                        >
                            {data?.configuration?.nodeExecutionConfiguration?.dataset?.value ? (
                                <Grid xs={12} container alignItems="center">
                                    <img src={nodeIconMapper["powerBIRefreshNode"]} style={{ height: "35px" }} />
                                    <Typography className="size12 grayText" style={{ padding: "10px 10px" }}>
                                        Dataset Name = {data?.configuration?.nodeExecutionConfiguration?.dataset.value.name}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Grid xs={12} container alignItems="center">
                                    <img src={nodeIconMapper["powerBIRefreshNode"]} style={{ height: "35px", marginLeft: 5 }} />
                                    <Typography className="size12 grayText" style={{ padding: "10px 5px" }}>
                                        Double Click here to add Configration.
                                    </Typography>
                                </Grid>
                            )}
                        </div>
                    </Grid>
                    <Handle
                        type="target"
                        position={Position.Left}
                        id="datasource-node-target"
                        isConnectable={isConnectable}
                        style={{ height: 10, width: 10, backgroundColor: "#FD9567" }}
                    />
                    <HandleWrapper
                        nodeId={id}
                        handleId="source"
                        handleColor="#FD9567"
                        isConnectable={isConnectable}
                        data={data}
                        tooltipTitle="Click to connect node."
                        style={{
                            background: "white",
                            border: "3px solid #FD9567",
                            height: 12,
                            width: 12,
                        }}
                        position={Position.Right}
                    />
                    <NodeConfigrationDrawer
                        isDrawerOpen={isDrawerOpen}
                        setIsDrawerOpen={setIsDrawerOpen}
                        drawerTitle="Configure Power BI Node"
                    >
                        <Configration nodeId={id} nodeData={data} handleCloseDrawer={() => setIsDrawerOpen(false)} />
                    </NodeConfigrationDrawer>
                </div>
            </ContextMenu>
        </NodeStatusHOC>
    );
});

import {
    USER_LIST_LOADED,
    SET_USER_IS_LOADING,
    SET_USER,
    ME_USER_IS_LOADING,
    SET_NOTIFICATION_ENGINES,
    SET_NOTIFICATION_ENGINES_IS_LOADING,
    SET_ACCOUNT_TIMEZONE,
    SET_CREDENTIALS,
    SET_CREDENTIALS_IS_LOADING,
    SET_SELECTED_DATASOURCE,
    SET_DATA_USAGE_DATEWISE,
    SET_DATA_USAGE_DATEWISE_IS_LOADING,
    SET_API_KEYS,
    SET_API_KEYS_LOADING,
    // CHANGE_STATUS_OF_AN_API_KEY,
    // IS_CHANGE_STATUS_OF_AN_API_KEY_LOADING
    INVOICE_DATA_IS_LOADING,
    SET_INVOICE_DATA,
} from "../actionTypes/actionTypes";

const initialState = {
    users: [],
    user: null,
    notificationEngines: [],
    usersIsLoading: false,
    meUserIsLoading: false,
    notificationEnginesIsLoading: false,
    timezone: "GMT",
    selectedDatasource: null,
    credentials: [],
    credentialsIsLoading: false,
    datewiseDataUsage: [],
    datewiseDataUsageIsLoading: false,
    apiKeys: [],
    isApiKeysLoading: false,
    isChangeStatusApiKeyLoading: false,
    invoiceData: null,
    invoiceDataIsLoading: false,
};

export const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LIST_LOADED:
            return {
                ...state,
                users: action.payload,
            };
        case SET_USER_IS_LOADING:
            return {
                ...state,
                usersIsLoading: action.payload,
            };
        case ME_USER_IS_LOADING:
            return {
                ...state,
                meUserIsLoading: action.payload,
            };
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                timezone: action?.payload?.timezone,
            };
        case SET_NOTIFICATION_ENGINES:
            return {
                ...state,
                notificationEngines: action.payload,
            };
        case SET_NOTIFICATION_ENGINES_IS_LOADING:
            return {
                ...state,
                notificationEnginesIsLoading: action.payload,
            };
        case SET_ACCOUNT_TIMEZONE:
            return {
                ...state,
                timezone: action.payload,
            };
        case SET_CREDENTIALS:
            return {
                ...state,
                credentials: action.payload,
            };
        case SET_CREDENTIALS_IS_LOADING:
            return {
                ...state,
                credentialsIsLoading: action.payload,
            };
        case SET_SELECTED_DATASOURCE:
            return {
                ...state,
                selectedDatasource: action.payload,
            };
        case SET_DATA_USAGE_DATEWISE_IS_LOADING:
            return {
                ...state,
                datewiseDataUsageIsLoading: action.payload,
            };
        case SET_DATA_USAGE_DATEWISE:
            return {
                ...state,
                datewiseDataUsage: action.payload,
            };
        case SET_API_KEYS_LOADING:
            return {
                ...state,
                isApiKeysLoading: action.payload,
            };
        case SET_API_KEYS:
            return {
                ...state,
                apiKeys: action.payload,
            };
        // case IS_CHANGE_STATUS_OF_AN_API_KEY_LOADING:
        //     return {
        //         ...state,
        //         isChangeStatusApiKeyLoading: action.payload,
        //     }
        // case CHANGE_STATUS_OF_AN_API_KEY:
        //     return {
        //         ...state,
        //         status_message : action.payload.message
        //     }
        case INVOICE_DATA_IS_LOADING:
            return {
                ...state,
                invoiceDataIsLoading: action.payload,
            };
        case SET_INVOICE_DATA:
            return {
                ...state,
                invoiceData: action.payload,
            };
        default:
            return state;
    }
};

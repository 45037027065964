import {
    USER_LIST_LOADED,
    SET_USER_IS_LOADING,
    SET_NOTIFICATION_ENGINES,
    SET_NOTIFICATION_ENGINES_IS_LOADING,
    SET_CREDENTIALS,
    SET_CREDENTIALS_IS_LOADING,
    SET_USER,
    ME_USER_IS_LOADING,
    SET_DATA_USAGE_DATEWISE_IS_LOADING,
    SET_DATA_USAGE_DATEWISE,
    SET_API_KEYS_LOADING,
    SET_API_KEYS,
    // CHANGE_STATUS_OF_AN_API_KEY,
    // IS_CHANGE_STATUS_OF_AN_API_KEY_LOADING
    SET_INVOICE_DATA,
    INVOICE_DATA_IS_LOADING,
    LATEST_ANNOUNCEMENTS,
    LATEST_ANNOUNCEMENTS_IS_LOADING,
    ALL_ANNOUNCEMENTS,
    ALL_ANNOUNCEMENTS_IS_LOADING,
} from "../actionTypes/actionTypes";

import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";
import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";

const fetchUsersList = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        SET_USER_IS_LOADING,
        USER_LIST_LOADED,
        {
            module: "user",
            method: "getAll",
        },
        HttpErrorHandler
    );
};

const fetchApiKeys = () => (dispatch) => {
    apiWrapper(
        dispatch,
        SET_API_KEYS_LOADING,
        SET_API_KEYS,
        {
            module: "user",
            method: "getApiKeys",
        },
        HttpErrorHandler
    );
};

const fetchDateWiseDataUsage = (to, from, readyDataSource) => (dispatch) => {
    apiWrapper(
        dispatch,
        SET_DATA_USAGE_DATEWISE_IS_LOADING,
        SET_DATA_USAGE_DATEWISE,
        {
            module: "user",
            method: "getDateWiseCombinedData",
            apiData: {
                from_date: to,
                to_date: from,
                datasource_ids: readyDataSource,
            },
        },
        HttpErrorHandler
    );
};

const fetchNotificationEngines = () => (dispatch) => {
    apiWrapper(
        dispatch,
        SET_NOTIFICATION_ENGINES_IS_LOADING,
        SET_NOTIFICATION_ENGINES,
        {
            module: "notification",
            method: "getAllEngines",
        },
        HttpErrorHandler
    );
};

const fetchCredentials = (datasourceSlug) => (dispatch) => {
    apiWrapper(
        dispatch,
        SET_CREDENTIALS_IS_LOADING,
        SET_CREDENTIALS,
        {
            module: "datasource",
            method: "getCredentials",
            apiData: {
                slug: datasourceSlug,
            },
        },
        HttpErrorHandler
    );
};

const fetchUser = () => async (dispatch) => {
    apiWrapper(dispatch, ME_USER_IS_LOADING, SET_USER, {
        module: "user",
        method: "getUser",
    });
};
const fetchInvoiceData = () => async (dispatch) => {
    apiWrapper(dispatch, INVOICE_DATA_IS_LOADING, SET_INVOICE_DATA, {
        module: "user",
        method: "getInvoiceData",
    });
};

const fetchLatestAnnouncements = () => async (dispatch) => {
    apiWrapper(dispatch, LATEST_ANNOUNCEMENTS_IS_LOADING, LATEST_ANNOUNCEMENTS, {
        module: "webflowAnnouncements",
        method: "getLatestAnnouncement",
    });
};
const fetchAllAnnouncements = () => async (dispatch) => {
    apiWrapper(dispatch, ALL_ANNOUNCEMENTS_IS_LOADING, ALL_ANNOUNCEMENTS, {
        module: "webflowAnnouncements",
        method: "getAllAnnouncement",
    });
};

export {
    fetchUsersList,
    fetchNotificationEngines,
    fetchCredentials,
    fetchApiKeys,
    fetchUser,
    fetchDateWiseDataUsage,
    fetchInvoiceData,
    fetchLatestAnnouncements,
    fetchAllAnnouncements,
};

import React from "react";
import { Grid, Button, Switch } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckPermissions from "services/CheckPermissions";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import clsx from "clsx";
import { Link } from "react-router-dom";
import useApi from "hooks/useApi";
import { useDispatch } from "react-redux";
import { fetchTransformations } from "redux/actions/transformationsActions";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,
        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function TransformationActionButtons({ transformationId, fontSize }) {
    const classes = useStyles();
    const [reportLoading, setReportLoading] = React.useState(null);

    //api calls using useApi hook
    const [, transformationIsRunning, runTransformation] = useApi();
    const [, transfomrationIsDeleting, deleteTransformation] = useApi();

    const dispatch = useDispatch();

    const handleManualRun = async (transformationId) => {
        setReportLoading(transformationId);
        await runTransformation({
            module: "transformation",
            method: "runTransformation",
            successToast: "Transformation run initiated successfully.",
            apiData: {
                transformationId: transformationId,
            },
        });
        setReportLoading(null);
    };

    const handleDelete = async (transformationId) => {
        if (window.confirm("Are you sure you wanna delete this transformation?")) {
            setReportLoading(transformationId);
            await deleteTransformation({
                module: "transformation",
                method: "deleteTransformation",
                successToast: "Transformation deleted successfully.",
                apiData: {
                    transformationId: transformationId,
                },
            });
            dispatch(fetchTransformations());
            setReportLoading(null);
        } else {
            return;
        }
    };

    return (
        <div>
            <CheckPermissions requiredPermission={["transformation.delete"]}>
                <Tooltip title="Delete Transformation">
                    {transfomrationIsDeleting && reportLoading === transformationId ? (
                        <CircularProgress size={15} />
                    ) : (
                        <DeleteOutlineOutlinedIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(transformationId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["transformation.edit"]}>
                <Tooltip title="Edit Transformation">
                    <Link to={`/transformations/sql/edit/${transformationId}`}>
                        <EditOutlinedIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            fontSize="small"
                        />
                    </Link>
                </Tooltip>
            </CheckPermissions>
            <CheckPermissions requiredPermission={["transformation.run"]}>
                <Tooltip title="Run Transformation">
                    {transformationIsRunning && reportLoading === transformationId ? (
                        <CircularProgress size={15} />
                    ) : (
                        <PlayCircleOutlineIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            fontSize="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleManualRun(transformationId);
                            }}
                        />
                    )}
                </Tooltip>
            </CheckPermissions>
        </div>
    );
}

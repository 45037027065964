import React from "react";
import { useSelector } from "react-redux";
import { NotFound } from "./routing";

const checkForPermission = (userPermissions, reqPermissions) => {
    let check = false;

    if (!Array.isArray(reqPermissions)) {
        return true;
    }
    reqPermissions.forEach((requiredPermission) => {
        userPermissions.forEach((userPermission) => {
            if (requiredPermission === userPermission) {
                check = true;
            }
        });
    });

    return check;
};

const CheckPermissions = (props) => {
    const users = useSelector((state) => state.users);
    if (users.user == null) {
        return null;
    }
    if (props.requiredPermission.length === 0) {
        return props.children;
    }
    if (checkForPermission(users.user.permissions, props.requiredPermission)) {
        return props.children;
    }
    if (props.showNotFound) {
        return <NotFound />;
    }
    return null;
};

export default CheckPermissions;

import {
    SET_SELECTED_DATA_MODEL,
    SET_SELECTED_SEGMENT,
    SET_SELECTED_REVERSE_CRED,
    SET_SELECTED_REVERSE_CONNECTOR,
} from "../actionTypes/actionTypes";

const initialState = {
    selectedDataModel: null,
    selectedSegment: null,
    selectedCredential: null,
    selectedReverseConnector: null,
};

export const homepageReverseReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_DATA_MODEL:
            return {
                ...state,
                selectedDataModel: action.payload,
            };
        case SET_SELECTED_SEGMENT:
            return {
                ...state,
                selectedSegment: action.payload,
            };
        case SET_SELECTED_REVERSE_CRED:
            return {
                ...state,
                selectedCredential: action.payload,
            };
        case SET_SELECTED_REVERSE_CONNECTOR:
            return {
                ...state,
                selectedReverseConnector: action.payload,
            };
        default:
            return state;
    }
};
